import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';

import { RequestService } from '../../core/services/request/request.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { LoginUser, ForgotPassword, ResetPassword } from '../../interfaces/auth-layout.interface';

@Injectable({
    providedIn: 'root'
})
export class AuthLayoutService {

    constructor(
        private requestService: RequestService,
        private authService: AuthService
        ) { }

    get emailRemember(): string {
        return this.authService.emailRemember;
    }

    isAuthentication() {
        return this.authService.verifyAuthentication().pipe( take(1 ));
    }       

    login(login: LoginUser, remember: boolean): Observable<any> {
        return this.requestService.post('auth/login', {... login, web: true}, 30000, false).pipe(
            tap( (val: AjaxResponse) => {
                if(val.status === 200) {
                    if(val.response.userinfo) {
                        this.authService.userValue = val.response.userinfo;
                    }
                    if(val.response.token) {
                        this.authService.tokenValue = val.response.token;
                    }
                    if(remember === true) {
                        this.authService.emailRemember = login.email;
                    } else {
                        this.authService.emailRemember = undefined;
                    }
                }
            }),
            map( (data: AjaxResponse) => data.response )
        );
    }

    forgotPassword( reset: ForgotPassword): Observable<any> {
        return this.requestService.post('auth/forgot-password', reset, 30000, false).pipe(            
            map( (data: AjaxResponse) => data.response )
        );
    }

    resetPassword( reset: ResetPassword, token: string): Observable<any> {
        return this.requestService.post(`auth/reset-password/${ token }`, reset, 30000, false).pipe(
            map( (data: AjaxResponse) => data.response )
        );
    }
}
