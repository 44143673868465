import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { ComponentService } from "../component.service";
import { AuthService } from "../../core/services/auth/auth.service";
import { RolesService } from "../../services/roles/roles.service";

var misc: any = {
    sidebar_mini_active: true
};

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, OnDestroy {
    public menuItems: any[];
    public isCollapsed = true;

    public itemSelected: string = 'Dashboard';
    public childItemSelected: string = '';
    public miniIstActive: boolean = false;

    public subscription: Subscription;
    public subsChangeMenu: Subscription;

    public hElement: HTMLElement;
    public hElementRef: HTMLElement;

    public intoButton: boolean = false;

    constructor(
        private router: Router, 
        private componentService: ComponentService,
        private renderer: Renderer2,
        private elRef: ElementRef,
        private authService: AuthService,
        private rolesService: RolesService
        ) { 

            this.hElement = this.renderer.selectRootElement('body', true);
            this.hElementRef = this.elRef.nativeElement;
            this.subsChangeMenu = this.componentService.changeMenu$.subscribe( change => {
                this.itemActive(change.menu, change.submenu);
            });
        }
    
    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
        if(this.subsChangeMenu) {
            this.subsChangeMenu.unsubscribe();
        }
    }

    ngOnInit() {
        // this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.router.events.subscribe(event => {
            this.isCollapsed = true;
        });

        this.subscription = this.componentService.changeEnd$.subscribe( change => {
            this.miniIstActive = change;
        });

        let role = this.authService.userValue.role;
        if(role) {
            this.rolesService.getRole( role ).subscribe( role => {
                this.menuItems = role.menus;
                let listPath: Array<string> = role.menus.map( m => m.path).filter(f => f !== undefined);
                let childFilter = role.menus.filter( f => (f.children && f.children.length > 0)).map( m => m.children.map(c => `${ m.path }/${ c.path }`)).reduce((acc, val) => acc.concat(val));
                if(role.extras && role.extras.length > 0) {
                    listPath = listPath.concat(role.extras);
                }
                this.authService.routerList = listPath.concat(childFilter);
            });
        }

    }
    onMouseEnterSidenav() {
        if(this.intoButton===false) {

            if (!this.hElement.classList.contains("g-sidenav-pinned")) {
                this.hElement.classList.add("g-sidenav-show");
                const classPin: Element = this.hElementRef.getElementsByClassName('button-pin-custom-hc')[0];
                if(classPin) {
                    classPin.classList.add('move-pin-button');
                }
            }        
            this.sidebarShow();
        }
    }
    onMouseLeaveSidenav() {
        if(this.intoButton===false) {
            if (!this.hElement.classList.contains("g-sidenav-pinned")) {
                this.hElement.classList.remove("g-sidenav-show");
                const classPin: Element = this.hElementRef.getElementsByClassName('button-pin-custom-hc')[0];
                if(classPin) {
                    classPin.classList.remove('move-pin-button');
                }
            }
            this.sidebarShow();
        }
    }

    onMouseEnterButton() {
        this.intoButton = true;
    }

    onMouseLeaveButton() {
        this.intoButton = false;
    }


    minimizeSidebar() {
        const sidenavToggler: Element = this.hElementRef.getElementsByClassName('sidenav-toggler')[0];
        if (this.hElement.classList.contains("g-sidenav-pinned")) {
            misc.sidebar_mini_active = true;
        } else {
            misc.sidebar_mini_active = false;
        }
        if (misc.sidebar_mini_active === true) {
            this.hElement.classList.remove("g-sidenav-pinned");
            this.hElement.classList.add("g-sidenav-hidden");
            sidenavToggler.classList.remove("active");
            misc.sidebar_mini_active = false;
        } else {
            this.hElement.classList.add("g-sidenav-pinned");
            this.hElement.classList.remove("g-sidenav-hidden");
            sidenavToggler.classList.add("active");
            misc.sidebar_mini_active = true;
        }
    }

    itemActive(item: string, subitem: string): void {
        this.itemSelected = item;
        this.childItemSelected = subitem;
    }

    sidebarShow() {
        if (this.hElement.classList.contains("g-sidenav-pinned")) {
            this.componentService.changeSidebar = false;
        } else {
            this.componentService.changeSidebar = true;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.sidebarShow();
    }
}
