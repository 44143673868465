import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { MessageService } from '../../services/message/message.service';

@Injectable({
    providedIn: 'root'
})
export class RouterGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private authService: AuthService,
        private router: Router,
        private ms: MessageService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            const currentUrl = this.router.url;
            const goToUrl = state.url.split('?')[0];

            return goToUrl === '/dashboard' || this.authService.verifyRouterValid(this.clearUrl(route, goToUrl)).pipe(
                tap(valid => {
                    if(!valid) {
                        this.ms.viewMessageToast( 'warning', `No cuenta con los permisos para acceder :(` );
                        this.router.navigate([currentUrl])
                    }
                })
            );
    }
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const currentUrl = this.router.url;
        const goToUrl = state.url.split('?')[0];

        const currentNavigation = this.router.getCurrentNavigation();
        const selectId = currentNavigation.extras?.state?.idBase;

        return goToUrl === '/dashboard' || this.authService.verifyRouterValid(this.clearUrl(childRoute, goToUrl, selectId)).pipe(
            tap(valid => {
                if(!valid) {
                    this.ms.viewMessageToast( 'warning', `No cuenta con los permisos para acceder :(` );
                    this.router.navigate([currentUrl])
                }
            })
        );
    }
    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return route.path === 'dashboard' || this.authService.verifyRouterValid(`/${ route.path }`).pipe(
            tap(valid => {
                if(!valid) {
                    this.ms.viewMessageToast( 'warning', `No cuenta con los permisos para acceder :(` );
                    this.router.navigate(['/auth/login']);
                }
            })
        );
    }

    clearUrl(params: ActivatedRouteSnapshot, urlGo: string, idBase: string = undefined) {
        let url = urlGo;
        params.paramMap.keys.forEach(key => {
            url = url.replace(`/${ params.paramMap.get(key) }`, '');
        });
        if(idBase !== undefined) {
            url = url.replace(`/${ idBase }`, '');
        }
        return url;
    }
}
