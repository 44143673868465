import { Component, OnInit, HostListener } from "@angular/core";

@Component({
    selector: "app-admin-layout",
    templateUrl: "./admin-layout.component.html",
    styleUrls: ["./admin-layout.component.scss"]
})
export class AdminLayoutComponent implements OnInit {
    isMobileResolution: boolean;

    constructor() {
        if (window.innerWidth < 1200) {
            this.isMobileResolution = true;
        } else {
            this.isMobileResolution = false;
        }
    }
    @HostListener("window:resize", ["$event"])
    isMobile(event) {
        if (window.innerWidth < 1200) {
            this.isMobileResolution = true;
        } else {
            this.isMobileResolution = false;
        }
    }
    ngOnInit() { }
}
