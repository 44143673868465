import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./core/guards/auth/auth.guard";
import { NewComponent } from "./pages/auth/new/new.component";
import { RouterGuard } from "./core/guards/router/router.guard";

const routes: Routes = [
    {
        path: "",
        redirectTo: "auth",
        pathMatch: "full"
    },
    {
        path: "",
        component: AdminLayoutComponent,
        canActivate: [AuthGuard, RouterGuard],
        canActivateChild: [RouterGuard],
        children: [
            {
                path: 'dashboard',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: "./pages/dashboards/dashboards.module#DashboardsModule"
            },
            {
                path: 'promotions',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/promotions/promotions.module#PromotionsModule'
            },
            {
                path: 'commercial-allies',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/commercial-allies/commercial-allies.module#CommercialAlliesModule'
            },
            {
                path: 'swaps',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/swaps/swaps.module#SwapsModule'
            },
            {
                path: 'games',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/games/games.module#GamesModule'
            },
            {
                path: 'claims',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/claims/claims.module#ClaimsModule'
            },
            {
                path: 'contents',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/contents/contents.module#ContentsModule'
            },
            {
                path: 'users',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/users/users.module#UsersModule'
            },
            {
                path: 'gems-tokens',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/gems-tokens/gems-tokens.module#GemsTokensModule'
            },
            {
                path: 'faqs',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/faqs/faqs.module#FaqsModule'
            },
            {
                path: 'administrators',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/administrators/administrators.module#AdministratorsModule'
            },
            {
                path: 'settings',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/settings/settings.module#SettingsModule'
            },
            {
                path: 'paper-bins',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/paper-bins/paper-bins.module#PaperBinsModule'
            },
            {
                path: 'tutorials',
                canLoad: [AuthGuard, RouterGuard],
                loadChildren: './pages/tutorials/tutorials.module#TutorialsModule'
            }
        ]
    },
    {
        path: "",
        component: AuthLayoutComponent,
        children: [
            {
                path: 'auth',
                loadChildren: "./layouts/auth-layout/auth-layout.module#AuthLayoutModule"
            }
        ]
    },
    {
        path: "reset-password/:token",
        component: NewComponent
    },
    {
        path: "**",
        redirectTo: "auth"
    }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            useHash: true
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
