import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth/auth.service';
import { catchError } from 'rxjs/operators';
import { HttpResponseCodesEnum } from '../../enums/http-response-codes';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError( (err: HttpErrorResponse) => {


                if(err.status === HttpResponseCodesEnum.UNAUTHORIZED) {
                    if(this.authService.isAuthenticated()) {
                        this.authService.removeStorage();
                    }
                    this.router.navigate(['/auth/login']);
                }

                if(err.status === HttpResponseCodesEnum.FORBIDDEN) {
                    // mostrar mensaje de: Acceso denegado
                    this.router.navigate(['/dashboard']);
                }                

                return throwError(err);
            })
        );
    }
}
