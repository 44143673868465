import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root'
})
export class LocalSecureService {

    constructor(private storageService: StorageService) { }

    setJsonValue(key: string, value: any) {
        this.storageService.secureStorage.setItem(key, value);
    }

    getJsonValue(key: string) {
        return this.storageService.secureStorage.getItem(key);
    }

    removeItem(key: string) {
        return this.storageService.secureStorage.removeItem(key);
    }

    clearToken() {
        return this.storageService.secureStorage.clear();
    }
}
