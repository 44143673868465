import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageService } from '../../../core/services/message/message.service';

import { CONFMSGRESP } from '../../../core/services/message/message.constant';

import { InputConfMessage } from '../../../interfaces/message.interface';

import { AuthLayoutService } from '../../../layouts/auth-layout/auth-layout.service';

@Component({
    selector: 'app-new',
    templateUrl: './new.component.html',
    styleUrls: ['../auth.component.scss'],
    providers: [MessageService]
})
export class NewComponent implements OnInit, OnDestroy {

    public loading: boolean = false;
    public token: string;

    public form: FormGroup = this.fb.group({
        password: ['', Validators.required],
        password2: ['']
    });

    public viewValue: boolean = false;
    public viewValue2: boolean = false;

    focus: boolean;
    focus1: boolean;

    public hElement: HTMLElement;

    constructor(
        private fb: FormBuilder,
        private activedRoute: ActivatedRoute,
        private authLayoutService: AuthLayoutService,
        private renderer: Renderer2,
        private ms: MessageService,
        private router: Router
        ) {
        this.hElement = this.renderer.selectRootElement('body', true);

        this.form.controls['password2'].setValidators([
            Validators.required,
            this.passwordNotEquals.bind( this.form )
        ]);
    }

    ngOnInit(): void {
        this.hElement.classList.add("background-gradient-orange");
        this.token = this.activedRoute.snapshot.paramMap.get('token');
    }

    ngOnDestroy() {
        this.hElement.classList.remove("background-gradient-orange");
    }

    disableFormFields(disbled: boolean): void {
        for (const control of Object.keys(this.form.controls)) {
            if(disbled === true) {
                this.form.get(control).disable();
            } else {
                this.form.get(control).enable();
            }
        }
    }

    inputIsValid(fieldName: string): boolean {
        return this.form.controls[fieldName].errors &&
            this.form.controls[fieldName].touched;
    }

    save(): void {
        this.form.controls['password2'].updateValueAndValidity();
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.disableFormFields(true);
        
        let reset = {...this.form.value};
        delete reset.password2;
        
        this.loading = true;
        this.authLayoutService.resetPassword(reset, this.token).subscribe(
            async data => {

                this.loading = false;

                let msgCnf: InputConfMessage = {...CONFMSGRESP};        
                msgCnf.buttonText = 'Aceptar';
                msgCnf.titleText = 'La contraseña se ha actualizado';
                await this.ms.message( msgCnf );

                this.router.navigate(['/auth/login']);
            },
            error => {
                this.disableFormFields(false);
                this.loading = false;
            }            
        )

    }

    viewText(selected: number): void {
        if (selected === 1) {
            this.viewValue = !this.viewValue;
        }

        if (selected === 2) {
            this.viewValue2 = !this.viewValue2;
        }
    }

    passwordNotEquals(control: FormControl): { [s: string]: boolean } {
        let forma: any = this;
        if (control.value !== forma.controls['password'].value) {
            return {
                notEquals: true
            };
        }
        return null;
    }

}
