import { RouteInfo } from "../interfaces/component.interface";

export const ROUTES: RouteInfo[] = [
    {
        path: "/dashboard",
        title: "Dashboard",
        type: "link",
        icontype: "ni-shop",
    },
    {
        path: "/promotions",
        title: "Promociones",
        type: "sub",
        icontype: "fa fa-promotion",
        collapse: "promotions",
        isCollapsed: true,
        children: [
            { path: "new", title: "Crear nueva", type: "link" },
            { path: "active", title: "Activas", type: "link" },
            { path: "historic", title: "Histórico", type: "link" }
        ]
    },
    {
        path: "/commercial-allies",
        title: "Aliados comerciales",
        type: "sub",
        icontype: "fa fa-commercial-allies",
        collapse: "commercial-allies",
        isCollapsed: true,
        children: [
            { path: "new", title: "Crear nuevo", type: "link" },
            { path: "allies", title: "Aliados", type: "link" }
        ]
    },
    {
        path: "/swaps",
        title: "Canjes",
        type: "sub",
        icontype: "fa fa-swaps",
        collapse: "swaps",
        isCollapsed: true,
        children: [
            { path: "change-code", title: "Canjear código", type: "link" },
            { path: "redeemed", title: "Códigos canjeados", type: "link" }
        ]
    },
    {
        path: "/games",
        title: "Juegos",
        type: "link",
        icontype: "fa fa-games"
    },
    {
        path: "/claims",
        title: "Reclamos",
        type: "link",
        icontype: "fa fa-claims"
    },
    {
        path: "/contents",
        title: "Contenido",
        type: "sub",
        icontype: "fa fa-contents",
        collapse: "contents",
        isCollapsed: true,
        children: [
            { path: "commercial", title: "Comercial", type: "link" },
            { path: "gamification", title: "Gamification", type: "link" }
        ]
    },
    {
        path: "/users",
        title: "Usuarios",
        type: "sub",
        icontype: "fa fa-users",
        collapse: "users",
        isCollapsed: true,
        children: [
            { path: "new", title: "Crear nuevo", type: "link" },
            { path: "all", title: "Todos los usuarios", type: "link" }
        ]
    },
    {
        path: "/gems-tokens",
        title: "Gemas & fichas",
        type: "link",
        icontype: "fa fa-gems-tokens"
    },
    {
        path: "/faqs",
        title: "FAQS",
        type: "link",
        icontype: "fa fa-faqs"
    },
    {
        path: "/administrators",
        title: "Administradores",
        type: "link",
        icontype: "fa fa-administrators"
    },
    {
        title: "Herramientas",
        type: "item"
    },
    {
        path: "/settings",
        title: "Configuración",
        type: "link",
        icontype: "fa fa-setting"
    },
    {
        path: "/paper-bins",
        title: "Papelera",
        type: "link",
        icontype: "fa fa-paper-bin"
    },
    {
        path: "/tutorials",
        title: "Tutoriales",
        type: "link",
        icontype: "fa fa-tutorials"
    },
];