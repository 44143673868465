import { Component, OnInit, OnDestroy, Renderer2 } from "@angular/core";

@Component({
    selector: "app-auth-layout",
    templateUrl: "./auth-layout.component.html",
    styleUrls: ["./auth-layout.component.scss"]
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
    test: Date = new Date();
    public isCollapsed = true;

    public hElement: HTMLElement;

    constructor(private renderer: Renderer2) {
        this.hElement = this.renderer.selectRootElement('body', true);
    }

    ngOnInit() {
        this.hElement.classList.add("background-gradient-orange");
    }
    ngOnDestroy() {
        this.hElement.classList.remove("background-gradient-orange");
    }
}
