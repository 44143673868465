import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalSecureService } from '../local-secure/local-secure.service';

import { JwtHelperService } from '@auth0/angular-jwt';
import { UserInfo } from '../../../interfaces/auth-layout.interface';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private localSecureService: LocalSecureService) { }

    get tokenValue(): string {
        return this.localSecureService.getJsonValue('token');
    }

    set tokenValue(value: string) {
        this.localSecureService.setJsonValue('token', value);
    }

    get userValue(): UserInfo {
        return this.localSecureService.getJsonValue('userinfo');
    }

    set userValue(value: UserInfo) {
        this.localSecureService.setJsonValue('userinfo', value);
    }

    get routerList(): Array<string> {
        return this.localSecureService.getJsonValue('routerinfo');
    }

    set routerList(value: Array<string>) {
        this.localSecureService.setJsonValue('routerinfo', value);
    }

    get emailRemember(): string {
        return localStorage.getItem('rememberMail');
    }

    set emailRemember(email: string) {
        if(email === '' || email === undefined) {
            localStorage.removeItem('rememberMail')
        } else {
            localStorage.setItem('rememberMail', email);
        }
    }

    isAuthenticated(): boolean {
        const token = this.tokenValue;
        if(!token) {
            return false;
        }
        const helper = new JwtHelperService();
        const isExpired = helper.isTokenExpired(token);
        return (!isExpired);
    }

    verifyAuthentication(): Observable<boolean> {
        const token = this.tokenValue;
        if(!token) {
            return of(false);
        }

        const helper = new JwtHelperService();
        // const decodedToken = helper.decodeToken(token);        
        // const expirationDate = helper.getTokenExpirationDate(token);
        const isExpired = helper.isTokenExpired(token);        

        return of(!isExpired);

    }

    verifyRouterValid(url: string): Observable<boolean> {
        const list = this.routerList || [];
        return of(list.filter( l => l === url).length > 0);
    }


    removeStorage() {
        this.localSecureService.removeItem('token');
        this.localSecureService.removeItem('userinfo');
        this.localSecureService.removeItem('routerinfo');
    }

}
