import { InputConfMessage } from "../../../interfaces/message.interface";

export const CONFMSGEXPORT: InputConfMessage = {
    buttonText: 'PDF',                        
    buttonCancelText: 'EXCEL',
    buttonClass: 'btn btn-primary text-poppins-bold btn-grad button-modal-local mt-4',
    buttonCancelClass: 'btn btn-primary text-poppins-bold btn-grad button-modal-local mt-3 mb-4',
    titleClass: 'text-modal-title-32 text-color-back-blue',
    titleText: '¿En que formato desea exportar la información?',
    widthDialog: '900px',
    text: ''
};
export const CONFMSGRESP: InputConfMessage = {
    buttonText: '¡Súper!',
    buttonClass: 'btn btn-primary text-poppins-bold btn-grad button-modal-local mt-3 mb-4',
    titleClass: 'text-modal-title-32 text-color-back-blue',
    titleText: '¡Información descargada con éxito!',
    widthDialog: '900px',
    text: ''
};

export const CONFMSGINPUTFILE: InputConfMessage = {
    inputClass: 'text-input-code input-file-promoton text-poppins-light-small mb-3',
    imageClass: 'mt-6 mb-5',
    widthDialog: '900px',
    buttonText: 'Confirmar',
    buttonClass: 'btn btn-primary text-poppins-bold btn-grad button-modal-local mb-4',
    text: ''
};

export const CONFMSGFILE: InputConfMessage = {
    buttonText: '¡Súper!',
    buttonClass: 'btn btn-primary text-poppins-bold btn-grad button-modal-local mt-3 mb-4',
    titleClass: 'text-modal-title-32 text-color-back-blue',
    titleText: '¡Archivo cargado con éxito!',
    widthDialog: '700px',
    text: ''
};

export const CONFIRMMSG: InputConfMessage = {
    buttonText: 'Confirmar',                        
    buttonCancelText: 'Regresar',
    buttonClass: 'btn btn-primary text-poppins-bold btn-grad button-modal-local mt-4 mb-4 ml-2',
    buttonCancelClass: 'btn btn-primary text-poppins-bold button-modal-back mt-4 mb-4 mr-2',
    titleClass: 'text-modal-title-32 text-color-back-blue',
    titleText: '¿Seguro que quieres modificar una condición?',
    text: 'Esta acción afectará a toda la aplicación',
    actionClass: 'action-display-row-button',
    htmlContainerClass: 'text-color-orange',
    widthDialog: '900px',
    reverseButtons: true
};

export const NOTSAVE: InputConfMessage = {
    buttonText: 'Aceptar',
    buttonClass: 'btn btn-outline-danger text-poppins-bold button-modal-local-without-border mt-3 mb-4',
    titleClass: 'text-modal-title-32 text-color-back-blue',
    titleText: '¡No se ha podido guardar la información!, favor de intentar de nuevo',
    widthDialog: '900px',
    text: ''
};

export const NOTLOADIMAGE: InputConfMessage = {
    buttonText: 'Aceptar',
    buttonClass: 'btn btn-outline-danger text-poppins-bold button-modal-local-without-border mt-3 mb-4',
    titleClass: 'text-modal-title-32 text-color-back-blue',
    titleText: '¡La imagen no pudo ser cargada!',
    widthDialog: '900px',
    text: ''
};

export const CONFMSGUPLOADFILE: InputConfMessage = {
    buttonText: 'Confirmar',
    buttonClass: 'btn btn-primary text-poppins-bold btn-grad button-modal-local mt-3 mb-4',
    buttonCancelClass: 'btn btn-primary text-poppins-bold button-modal-back mt-4 mb-4 mr-2',
    titleClass: 'text-modal-title-32 text-color-back-blue',
    titleText: '',
    widthDialog: '700px',
    text: ''
};