import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { ajax, AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

import { MessageService } from '../message/message.service';
import { environment } from '../../../../environments/environment';
import { HttpResponseCodesEnum } from '../../enums/http-response-codes';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { SearchField } from '../../../interfaces/services.interface';

@Injectable()
export class RequestService {

    private api:any = environment.apiUrl;

    displayError = (err: AjaxError) => {
        switch (err.status) {
            case HttpResponseCodesEnum.UNAUTHORIZED:
                if(this.authService.isAuthenticated()) {
                    this.authService.removeStorage();
                }
                this.ms.viewMessageToast( 'error', `Acceso vencido, debe volver a iniciar sesión :(` )
                this.router.navigate(['/auth/login']);                
                break;
            case HttpResponseCodesEnum.FORBIDDEN:
                // mostrar mensaje de: Acceso denegado
                this.router.navigate(['/dashboard']);
                break;        
            default:
                this.ms.viewMessageToast( 'error', `No fue posible consultar el servicio [${ err.status }] :( = ${ err.request?.url }` )
                break;
        }

        return throwError(err);

        // throw new Error( err.message );
        // return of([]);
    };

    constructor(
        private authService: AuthService,
        private ms: MessageService,
        private router: Router,
        private http: HttpClient
        ) { }

    post(url: string, body: any, timeout: number = 30000, token: boolean = true): Observable<any> {
        return ajax({
            url: `${ this.api }/${ url }`,
            method: 'POST',
            headers: this.getHeaders( token ),
            body,
            timeout
        });
    }

    patch(url: string, body: any, timeout: number = 30000, token: boolean = true): Observable<any> {
        return ajax({
            url: `${ this.api }/${ url }`,
            method: 'PATCH',
            headers: this.getHeaders( token ),
            body,
            timeout
        });
    }

    deleted(url: string, timeout: number = 30000, token: boolean = true): Observable<any> {
        return ajax({
            url: `${ this.api }/${ url }`,
            method: 'DELETE',
            headers: this.getHeaders( token ),
            timeout
        });
    }

    get(url: string, timeout: number = 30000, token: boolean = true): Observable<any> {
        return ajax({
            url: `${ this.api }/${ url }`,
            method: 'GET',
            headers: this.getHeaders( token ),
            timeout
        }).pipe( catchError( this.displayError ) );
    }

    // get<T>(url: string, timeout: number = 30000, token: boolean = true): Observable<any> {
    //     return this.http.get<T>(
    //         `${ this.api }/${ url }`,
    //         { headers: this.getHeaders( token ) }
    //     ).pipe( catchError( this.displayError ) );
    // }

    getLink(url: string, timeout: number = 30000, token: boolean = true): Observable<any> {
        return ajax({
            url: `${ this.api }/${ url }`,
            method: 'GET',
            headers: this.getHeaders( token ),
            timeout
        }).pipe( 
            map(this.handleDownloadLink),
            catchError( this.displayError )
        );
    }

    getFile(url: string, timeout: number = 30000, token: boolean = true): Observable<any> {
        return ajax({
            url: `${ this.api }/${ url }`,
            method: 'GET',
            responseType: 'blob',
            headers: this.getHeaders( token, true ),
            timeout
        }).pipe(
            map(this.handleDownloadSuccess),
            catchError( this.displayError )
        );
    }

    handleDownloadSuccess(response: AjaxResponse) {
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(response.response);
    
        const disposition = response.xhr.getResponseHeader('Content-Disposition');
        if (disposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                const filename = matches[1].replace(/['"]/g, '');
                downloadLink.setAttribute('download', filename);
            }
        }
    
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    handleDownloadLink(response: AjaxResponse) {
        const downloadLink = document.createElement('a');
        if(response.response.urlFile && response.response.urlFile !== '') {
            downloadLink.href = response.response.urlFile;
            const entriesName = response.response.urlFile.split('/');
            
            downloadLink.setAttribute('download', decodeURI(entriesName.at(-1)));
            document.body.appendChild(downloadLink);
            downloadLink.target = '_blank';
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }    
    }


    uploadFile(url: string, keyParam: string, file: File, token: boolean = true): Observable<any> {

        const promise = new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();

            formData.append( keyParam, file, file.name);

            xhr.onreadystatechange = function() {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === HttpResponseCodesEnum.OK ) {
                        resolve( JSON.parse(xhr.response) );
                    } else {
                        reject( xhr.response );
                    }
                }
            };

            xhr.open('POST', `${ this.api }/${ url }`, true);
            if(token === true) {

            }
            xhr.setRequestHeader('Authorization', `Bearer ${ this.authService.tokenValue }`);
            xhr.send(formData);
        });

        return from(promise);        
    }

    getByPage(url: string, limit: number = 10, skip: number = 0, search: Array<SearchField> = [], paramInit: string = undefined) : Observable<any> {
        let params = '';

        if(paramInit) {
            params += params === '' ? '?' : '&';
            params += paramInit;
        }

        if(limit !== undefined) {
            params += params === '' ? '?' : '&';
            params += `limit=${ limit > 0 ? limit : 1000 }`;
        }
        if(skip !== undefined) {
            params += params === '' ? '?' : '&';
            params += `skip=${ skip }`;
        }
        if(search !== undefined && search.length > 0) {
            search.forEach( s => {
                params += params === '' ? '?' : '&';
                params += `field=${ s.field }&value=${ s.value }&match=${ s.match }`;
            });
        }
        return this.get(`${ url }${ params }`, null).pipe(
            map( (data: AjaxResponse) => data.response )
        );
    }


    getHeaders(requiredToken: boolean, hedersFiles: boolean = false): any {
        let headers: any = {
            'Content-Type': 'application/json'
        }
        if(requiredToken === true) {
            headers = {... headers, 'Authorization': `Bearer ${ this.authService.tokenValue }`};
        }
        if(hedersFiles === true) {
            headers = {... headers, 'Accept': 'text/plain, */*', 'Cache-Control': 'no-cache'};
        }
        return headers;
    }
}
