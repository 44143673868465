import { Component, OnInit, Renderer2 } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Location } from "@angular/common";
import { Subscription } from "rxjs";

import { ROUTES } from "../menu-items.const";
import { ComponentService } from "../component.service";
import { AuthService } from "../../core/services/auth/auth.service";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
    // public focus;
    public listTitles: any[];
    public location: Location;
    public subscription: Subscription;

    public miniIstActive: boolean = false;

    public hElement: HTMLElement;

    public userName = '';
    public avatar = '';
    
    sidenavOpen: boolean = true;
    constructor(
        location: Location,
        private router: Router,
        private componentService: ComponentService,
        private renderer: Renderer2,
        private authService: AuthService
    ) {        
        
        this.hElement = this.renderer.selectRootElement('body', true);
        this.location = location;
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator

            }
            if (event instanceof NavigationEnd) {
                // Hide loading indicator

                if (window.innerWidth < 1200) {
                    this.hElement.classList.remove('g-sidenav-pinned');
                    this.hElement.classList.add('g-sidenav-hidden');
                    this.sidenavOpen = false;
                }
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
            }
        });

    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnInit() {
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        this.subscription = this.componentService.changeEnd$.subscribe( change => {
            this.miniIstActive = change;
        });

        this.userName = `${this.authService.userValue.firstname ? this.authService.userValue.firstname : '-'} ${this.authService.userValue.firstlastname ? this.authService.userValue.firstlastname : '-'}`;
        this.avatar = this.authService.userValue.avatar ? this.authService.userValue.avatar : 'assets/img/promoton/png/no_avatar.png';

    }
    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === "#") {
            titlee = titlee.slice(1);
        }

        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;
            }
        }
        return "Dashboard";
    }
    
    openSidebar() {
        
        if (this.hElement.classList.contains("g-sidenav-pinned")) {
            this.hElement.classList.remove("g-sidenav-pinned");
            this.hElement.classList.add("g-sidenav-hidden");
            this.sidenavOpen = false;

            this.sidebarShow();
        } else {
            this.hElement.classList.add("g-sidenav-pinned");
            this.hElement.classList.remove("g-sidenav-hidden");
            this.sidenavOpen = true;

            this.sidebarShow();
        }
    }
    toggleSidenav() {
        
        if (this.hElement.classList.contains("g-sidenav-pinned")) {
            this.hElement.classList.remove("g-sidenav-pinned");
            this.hElement.classList.add("g-sidenav-hidden");
            this.sidenavOpen = false;

            this.sidebarShow();
        } else {
            this.hElement.classList.add("g-sidenav-pinned");
            this.hElement.classList.remove("g-sidenav-hidden");
            this.sidenavOpen = true;

            this.sidebarShow();
        }
    }

    sidebarShow() {
        
        if (this.hElement.classList.contains("g-sidenav-pinned")) {
            this.componentService.changeSidebar = false;
        } else {
            this.componentService.changeSidebar = true;
        }
    }

    logout() {
        this.authService.removeStorage();
        this.router.navigate(['/auth/login']);
    }

}
