import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ChangeMenu } from '../interfaces/component.interface';

@Injectable({
    providedIn: 'root'
})
export class ComponentService {

    public change: Subject<boolean>= new Subject<boolean>();
    public changeEnd$ = this.change.asObservable();

    public changeMenu: Subject<ChangeMenu>= new Subject<ChangeMenu>();
    public changeMenu$ = this.changeMenu.asObservable();

    constructor() { }

    set changeSidebar(change: boolean) {
        this.change.next(change);
    }

    set changeMenuObs(menu: ChangeMenu) {
        this.changeMenu.next(menu);
    }
}
