import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2'

import { InputConfMessage, SweetAlertCustomClassExt } from '../../../interfaces/message.interface';
import { ItemColumn, ResultRowsFile } from '../../../interfaces/shared.interface';

@Injectable()
export class MessageService {

    constructor() { }

    message(config: InputConfMessage): Promise<SweetAlertResult<any>> {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: config.buttonClass ? config.buttonClass : 'btn btn-success',
                title: config.titleClass ? config.titleClass : 'text-color-back-blue'
            },
            buttonsStyling: false
        })

        return swalWithBootstrapButtons.fire({
            title: config.titleText ? config.titleText : '',
            text: config.text ? config.text : '',
            width: config.widthDialog ? config.widthDialog : '32rem',
            imageUrl: 'assets/img/promoton/svg/img-logo-login.svg',
            imageWidth: 145,
            imageHeight: 93,
            imageAlt: 'Promoton',
            confirmButtonText: config.buttonText ? config.buttonText : 'Aceptar',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
        });
    }

    messageConfirmBtnColumn(config: InputConfMessage): Promise<SweetAlertResult<any>> {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                actions: config.actionClass ? config.actionClass : 'action-display-column-button',
                confirmButton: config.buttonClass ? config.buttonClass : 'btn btn-success',
                cancelButton: config.buttonCancelClass ? config.buttonCancelClass : 'btn btn-success',
                title: config.titleClass ? config.titleClass : 'text-color-back-blue',
                container: config.containerClass ? config.containerClass : '',
                htmlContainer: config.htmlContainerClass ? config.htmlContainerClass : 'text-color-back-blue'
            },
            buttonsStyling: false
        });

        return swalWithBootstrapButtons.fire({
            title: config.titleText ? config.titleText : '',
            text: config.text ? config.text : '',
            width: config.widthDialog ? config.widthDialog : '32rem',
            imageUrl: 'assets/img/promoton/svg/img-logo-login.svg',
            imageWidth: 145,
            imageHeight: 93,
            imageAlt: 'Promoton',
            confirmButtonText: config.buttonText ? config.buttonText : 'Aceptar',
            cancelButtonText: config.buttonCancelText ? config.buttonCancelText : 'Cancelar',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showCancelButton: true,
            backdrop: true,
            reverseButtons: config.reverseButtons ? config.reverseButtons : false
        });
    }

    messageInput(config: InputConfMessage): Promise<SweetAlertResult<any>> {

        const custom: SweetAlertCustomClassExt = {
            confirmButton: config.buttonClass ? config.buttonClass : 'btn btn-success',
            title: config.titleClass ? config.titleClass : 'text-color-back-blue',
            htmlContainer: config.htmlContainerClass ? config.htmlContainerClass : 'text-color-back-blue',
            inputLabel: config.inputLabelClass ? config.inputLabelClass : '',
            input: config.inputClass ? config.inputClass : ''
        };

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: custom,
            buttonsStyling: false
        })

        return swalWithBootstrapButtons.fire({
            title: config.titleText ? config.titleText : '',
            text: config.text ? config.text : '',
            width: config.widthDialog ? config.widthDialog : '32rem',
            imageUrl: 'assets/img/promoton/svg/img-logo-login.svg',
            imageWidth: 145,
            imageHeight: 93,
            imageAlt: 'Promoton',
            confirmButtonText: config.buttonText ? config.buttonText : 'Aceptar',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            backdrop: true,
            input: 'text',
            inputLabel: config.inputLabel ? config.inputLabel : '',
            inputPlaceholder: config.inputPlaceholder ? config.inputPlaceholder : '',
            inputValue: '',
            showLoaderOnConfirm: true,
            preConfirm: config.preConfirm ? config.preConfirm : undefined,
            inputValidator: (value) => {
                if (!value) {
                    return 'Necesitas ingresar un código!'
                }
            }
        });
    }

    messageConfirm(config: InputConfMessage): Promise<SweetAlertResult<any>> {

        const custom: SweetAlertCustomClassExt = {
            confirmButton: config.buttonClass ? config.buttonClass : 'btn btn-success',
            title: config.titleClass ? config.titleClass : 'text-color-back-blue',
            htmlContainer: config.htmlContainerClass ? config.htmlContainerClass : 'text-color-back-blue'
        };

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: custom,
            buttonsStyling: false
        })

        return swalWithBootstrapButtons.fire({
            title: config.titleText ? config.titleText : '',
            html: config.html ? config.html : '',
            width: config.widthDialog ? config.widthDialog : '32rem',
            confirmButtonText: config.buttonText ? config.buttonText : 'Aceptar',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            backdrop: true
        });
    }

    async messageFile(config: InputConfMessage, columns: Array<ItemColumn> = undefined, nameLayout: string = 'layout'): Promise<SweetAlertResult<any>> {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: config.buttonClass ? config.buttonClass : 'btn btn-success',
                denyButton: config.buttonClass ? config.buttonClass : 'btn btn-primary',
                title: config.titleClass ? config.titleClass : 'text-color-back-blue',
                image: config.imageClass ? config.imageClass : '',
                input: config.inputClass ? config.inputClass : '',
                htmlContainer: config.htmlContainerClass ? config.htmlContainerClass : 'text-color-back-blue'
            },
            buttonsStyling: false
        })

        const { value: file } = await swalWithBootstrapButtons.fire({            
            title: config.titleText ? config.titleText : '',
            html: config.html ? config.html : '',
            width: config.widthDialog ? config.widthDialog : '32rem',
            imageUrl: 'assets/img/promoton/svg/img-logo-login.svg',
            imageWidth: 145,
            imageHeight: 93,
            imageAlt: 'Promoton',

            input: 'file',
            inputAttributes: {
                // 'accept': 'image/*,.csv,.txt',
                'accept': '.csv,.txt',
                'aria-label': 'archivo'
            },
            showCloseButton: true,
            confirmButtonText: config.buttonText ? config.buttonText : 'Aceptar',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            backdrop: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Necesitas ingresar un archivo!'
                }
            },
            showDenyButton: columns !== undefined,
            denyButtonText: 'Descargar Layout',
            reverseButtons: true,
            preDeny: (val) => {

                let csvData = ''; 
                for(let column of columns) {
                    if(csvData !== '') csvData += ',';
                    csvData += column.value;
                }
                
                const a = document.createElement("a"); 
                a.setAttribute('style', 'display:none;'); 
                document.body.appendChild(a); 
                var blob = new Blob([csvData], { type: 'text/csv' }); 
                var url = window.URL.createObjectURL(blob); 
                a.href = url; 
                a.download = `${nameLayout}.csv`; 
                a.click(); 
                a.remove();
                return false;
                
            }
        });

        return file;
    }

    viewMessageToast(icon: SweetAlertIcon, title: string) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          return Toast.fire({
            icon,
            title
          })
    }

    async messageConfirmLoadFile(config: InputConfMessage, cols: Array<ItemColumn>, data: ResultRowsFile, viewLayout: boolean = false, title: string = ''): Promise<SweetAlertResult<any>> {

        const custom: SweetAlertCustomClassExt = {
            confirmButton: config.buttonClass ? config.buttonClass : 'btn btn-success',
            cancelButton: config.buttonCancelClass ? config.buttonCancelClass : 'btn btn-success',
            title: config.titleClass ? config.titleClass : 'text-color-back-blue',
            htmlContainer: config.htmlContainerClass ? config.htmlContainerClass : 'text-color-back-blue'
        };

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: custom,
            buttonsStyling: false
        })

        let htmlDefault = `<div><h2>${ (title && title !== '') ? title : 'Favor de confirmar los datos a cargar:' }</h2></div><div class="card mb-3 mt-3" style="max-width: 340px; display: inline">`;
        if(data.data && data.data.length > 0) {
            htmlDefault += `<div class="row g-0 mr-0 ml-0"><h4 class="bg-success">Filas con formato correcto!</h4><div class="table-responsive-sm" style="display: inline-block;overflow: auto;max-height: 200px;"><table class="table table-hover"><thead class="thead-light" style="position: sticky;top: 0;z-index: 1;"><tr><th scope="col">#</th>`;
                                    
            cols.forEach(value => {
                htmlDefault += `<th scope="col">${ value.value }`;
                if(value?.required === true ) {
                    htmlDefault += `<div  style="display: contents;" class="text-danger"> **</div>`;
                }
                htmlDefault += `</th>`;
            });
            htmlDefault += `</tr></thead><tbody>`;

            data.data.forEach((value, index) => {
                htmlDefault += `<tr><th scope="row">${ index + 1 }</th>`;
                cols.forEach(col => {
                    htmlDefault += `<td style="text-align: left;">${ value[col.key] ? value[col.key] : '' }</td>`;
                });
                htmlDefault += `</tr>`;
            });
            htmlDefault += `</tbody></table></div></div>`;
        }        
        if(data.error && data.error.length > 0) {
            htmlDefault += `<div class="row g-0 mr-0 ml-0 mt-5"><h4 class="text-danger">Filas con Error</h4><div class="table-responsive-sm" style="display: inline-block;overflow: auto;max-height: 200px;"><table class="table table-hover"><thead class="thead-light" style="position: sticky;top: 0;z-index: 1;"><tr><th scope="col">#</th><th scope="col">Error</th></tr></thead><tbody>`;
            data.error.forEach((value, index) => {
                htmlDefault += `<tr><th scope="row">${ index + 1 }</th><td style="text-align: left;">${ value }</td></tr>`;
            });            
            htmlDefault += `</tbody></table></div></div>`;
        }
        if(viewLayout === true && cols && cols.length > 0) {
            htmlDefault += `<div class="row g-0 mr-0 ml-0 mt-5"><h4 class="text-primary">Valor por FILA en el Layout</h4><div class="table-responsive-sm" style="display: inline-block;overflow: auto;max-height: 200px;"><table class="table table-hover"><thead class="thead-light" style="position: sticky;top: 0;z-index: 1;"><tr><th scope="col"># Col</th><th scope="col">Valor</th><th scope="col">¿Requerido?</th><th scope="col">Tipo dato</th></tr></thead><tbody>`;
            cols.forEach((value, index) => {
                htmlDefault += `<tr><th scope="row">${ index + 1 }</th><td style="text-align: left;">${ value.value }</td><td style="text-align: left;">${ (value.required && value.required === true) ? 'SI' : 'NO' }</td><td style="text-align: left;">${ value.typeData ? value.typeData.replace(/number/gi, 'Número').replace(/date/gi, 'Texto con formato') : 'Texto' } ${ value.format ? (': ' + value.format.replace(/Y/gi, 'A')) : '' }</td></tr>`;
            });            
            htmlDefault += `</tbody></table></div></div>`;
        }
        htmlDefault += `</div>`;

        return swalWithBootstrapButtons.fire({
            title: config.titleText ? config.titleText : '',
            html: config.html ? config.html : htmlDefault,
            width: config.widthDialog ? config.widthDialog : '32rem',
            confirmButtonText: config.buttonText ? config.buttonText : 'Confirmar',
            cancelButtonText: config.buttonCancelText ? config.buttonCancelText : 'Cancelar',
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: false,
            showCancelButton: true,
            backdrop: true
        });
    }
}
