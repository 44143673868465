import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { map, take } from 'rxjs/operators';

import { Role, RoleMin } from '../../interfaces/roles.interface';

import { RequestService } from '../../core/services/request/request.service';

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    constructor(private requestService: RequestService) { }

    getRolesMin(typeRole: string): Observable<Array<RoleMin>> {
        return this.requestService.get(`role?type=${ typeRole }`, null).pipe(
            map( (data: AjaxResponse) => data.response.results ),
            map( (categories: Array<Role>) => {
                return categories.map( ({id, name}): RoleMin => {
                    return { id, name };
                } );
            })
        );
    }

    getRole(id: string): Observable<Role> {
        return this.requestService.get(`role/${ id }`).pipe( 
            take(1),
            map( (resp: AjaxResponse ) => resp.response)
        );
    }
    
}
